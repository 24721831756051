import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';

import { ErrorNotification } from './atoms/error-notification';
import { GET_UPLOAD, PUT_STATUS } from './endpoints';
import { useAxios } from './hooks';
import { Auth, Details, Upload, File, Status } from './types';

type SetAuth = (auth: Auth) => void;
type AuthContextValue = [Auth, SetAuth];

type SetUpload = Dispatch<SetStateAction<Upload>>;
type UploadContextValue = [Upload, SetUpload];

const authInitialValues: Auth = {
  isAuthenticated: false,
  signature: undefined,
  token: undefined
};

/* eslint-disable sort-keys */
const defaultDetails = {
  name: '',
  email: '',
  phoneNumber: '',
  dob: '',
  businessName: '',
  streetAddress: '',
  suburb: '',
  city: '',
  comment: ''
};
/* eslint-enable sort-keys */

/* eslint-disable sort-keys */
const uploadInitialValues: Upload = {
  caseEventId: '',
  id: '',
  timeDifference: 0,
  start: undefined,
  end: undefined,
  message: '',
  details: defaultDetails,
  files: []
};
/* eslint-enable sort-keys */

const AuthContext = createContext<AuthContextValue>([
  authInitialValues,
  () => null
]);

const UploadContext = createContext<UploadContextValue>([
  uploadInitialValues,
  () => null
]);

/**
 *
 * @param props
 */
export const VaultProvider: FC<{ readonly children: ReactNode }> = (props) => {
  const { error, callApi } = useAxios();
  // Try and load the auth from session storage
  const [auth, setAuthState] = useState<Auth>(() => {
    const lastAuth = sessionStorage.getItem('vault-auth');

    if (!lastAuth) {
      return authInitialValues;
    }

    try {
      const auth = JSON.parse(lastAuth);
      return auth;
    } catch (e) {
      sessionStorage.removeItem('vault-auth');
    }

    return authInitialValues;
  });

  const setAuthStateWrapper = (auth: Auth): void => {
    sessionStorage.setItem('vault-auth', JSON.stringify(auth));
    setAuthState(auth);
  };

  const uploadState = useState<Upload>(uploadInitialValues);

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    callApi({ auth, method: 'GET', url: GET_UPLOAD }).then(
      (response: AxiosResponse): void => {
        const { data } = response;
        const { details } = data;

        uploadState[1]({
          ...data,
          details: {
            ...details,
            dob: details.dob
              ? dayjs(details.dob).format('DD/MM/YYYY')
              : details.dob
          }
        });
      }
    );

    callApi({
      auth,
      data: { status: Status.STARTED },
      method: 'PUT',
      url: PUT_STATUS
    });
  }, [auth]);

  return (
    <AuthContext.Provider value={[auth, setAuthStateWrapper]}>
      <UploadContext.Provider value={uploadState}>
        {error && <ErrorNotification />}
        {props.children}
      </UploadContext.Provider>
    </AuthContext.Provider>
  );
};

/**
 *
 */
export function useAuth(): [Auth, SetAuth] {
  return useContext<AuthContextValue>(AuthContext);
}

/**
 *
 */
export function useDetails(): [Details, (details: Details) => void] {
  const [upload, setUpload] = useContext<UploadContextValue>(UploadContext);

  try {
    const savedDetails = localStorage.getItem('vault-details');
    if (savedDetails) {
      upload.details = JSON.parse(savedDetails);
    }
  } catch (e) {
    localStorage.removeItem('vault-details');
  }

  return [
    upload.details,
    (details: Details) => {
      localStorage.setItem('vault-details', JSON.stringify(details));
      setUpload({ ...upload, details });
    }
  ];
}

/**
 *
 */
export function useFiles(): [File[], (files: File[]) => void] {
  const [upload, setUpload] = useContext<UploadContextValue>(UploadContext);

  return [upload.files, (files: File[]) => setUpload({ ...upload, files })];
}

/**
 *
 */
export function useTime(): [number, (timeDifference: number) => void] {
  const [upload, setUpload] = useContext<UploadContextValue>(UploadContext);

  return [
    upload.timeDifference,
    (timeDifference: number) => setUpload({ ...upload, timeDifference })
  ];
}

/**
 *
 */
export function useUpload(): [Upload, SetUpload] {
  return useContext<UploadContextValue>(UploadContext);
}
