export interface File {
  id?: string;
  fileName: string;
  fileSize: number;
  hash: string;
}

export interface Details {
  name: string;
  email: string;
  phoneNumber: string;
  dob: string;
  businessName: string;
  streetAddress: string;
  suburb: string;
  city: string;
  comment?: string;
}

export interface Upload {
  caseEventId: string;
  id: string;
  timeDifference: number;
  start?: string;
  end?: string;
  message?: string;
  details: Details;
  files: File[];
}

export interface Signature {
  url: string;
  fields: {
    'bucket': string;
    'Policy': string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Signature': string;
    [field: string]: string;
  };
}

export enum Status {
  STARTED = 1,
  COMPLETE = 2
}

export interface Auth {
  isAuthenticated: boolean;
  token: string | undefined;
  signature: Signature | undefined;
}
